import callMixin from '@/mixins/sip/call.mixin'
import sendDtmfCodeMixin from '@/mixins/sip/send-dtmf-code.mixin'
export default {
  mixins: [callMixin, sendDtmfCodeMixin],
  methods: {
    callMonitoring (target, extraHeaders = [], dtmfCode = null) {
      extraHeaders = []
      this.call(target, extraHeaders, this.eventHandlers(dtmfCode))
    },
    eventHandlers (dtmfCode) {
      if (dtmfCode) {
        return {
          confirmed: data => {
            setTimeout(() => {
              this.sendDTMF(this.$sip.answeredCallSession, dtmfCode)
            }, 2000)
          }
        }
      } else return {}
    }
  }
}

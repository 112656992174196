import { mapActions, mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export default {
  data: () => ({
    hasData: {
      hasHeld: false,
      hasComment: false,
      hasDeviceInfo: false,
      hasServiceInfo: false,
      hasProviderInfo: false,
      hasSubscriberInfo: false
    },

    // Actual data
    heldData: {},
    commentData: {},
    deviceInfoData: {},
    serviceInfoData: {},
    providerInfoData: {},
    subscriberInfoData: {},

    position: '',
    esrkNumber: null,
    dataWasSent: false,
    preparedCADData: {},
    timeoutInterval: null
  }),
  computed: {
    ...mapGetters('global', ['authUserGetter', 'callGetter']),
    ...mapGetters('callInformation', [
      'dateTimeGetter',
      'esrkNumberGetter'
    ]),
    ...mapGetters('settings', ['getGlobalSetting', 'sipDefaultAddressGetter'])
  },
  watch: {
    hasData: {
      handler (val) {
        this.sendCADData()
      },
      deep: true
    },
    esrkNumberGetter (val) {
      this.esrkNumber = val

      this.sendCADData()
    }
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    updatedHELD (held) {
      this.heldData = { ...held.eventData }
      this.hasData.hasHeld = true
    },
    updatedADRComment (comment) {
      this.commentData = { ...comment.eventData }
      this.hasData.hasComment = true
    },
    updatedADRDeviceInfo (deviceInfo) {
      this.deviceInfoData = { ...deviceInfo.eventData }
      this.hasData.hasDeviceInfo = true
    },
    updatedADRServiceInfo (serviceInfo) {
      this.serviceInfoData = { ...serviceInfo.eventData }
      this.hasData.hasServiceInfo = true
    },
    updatedADRProviderInfo (providerInfo) {
      this.providerInfoData = { ...providerInfo.eventData }
      this.hasData.hasProviderInfo = true
    },
    updatedADRSubscriberInfo (subscriberInfo) {
      this.subscriberInfoData = { ...subscriberInfo.eventData }
      this.hasData.hasSubscriberInfo = true
    },
    prepareCADData () {
      const phone = this.subscriberInfoData.SubscriberData?.vcard?.tel?.uri

      let companyID = this.providerInfoData.ProviderID || ''

      if (companyID) {
        companyID = companyID.toString()
        companyID = companyID.split(':')
        companyID = companyID.pop()
      }

      return {
        lat: this.heldData.lat || '',
        lon: this.heldData.lng || '',
        city: `${this.heldData.A3 || ''} ${this.heldData.PC || ''}`,
        date: Intl.DateTimeFormat('en', { month: '2-digit', day: '2-digit' }).format(new Date(this.dateTimeGetter)),
        time: Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false, minute: '2-digit' }).format(new Date(this.dateTimeGetter)),
        phone: phone ? phone.replace('tel:', '').replace('+1', '') : '',
        state: this.heldData.A1 || '',
        house: `${this.heldData.HNP || ''} ${this.heldData.HNO || ''}`,
        street: this.getCADStreet(),
        companyID: companyID,
        houseSufix: this.heldData.HNS,
        pilotNumber: this.esrkNumber?.replace('+1', ''),
        locationInfo: this.heldData.LOC,
        telcoComment: this.commentData.Comment || '',
        customerName: this.subscriberInfoData.SubscriberData?.vcard?.fn?.text || '',
        classOfService: this.serviceInfoData.LegacyClassOfService || this.serviceInfoData.ServiceType || '',
        prefixDirection: this.$CAD.getAlias(4).getValue(this.heldData.PRD)
      }
    },
    getCADStreet () {
      return `${this.heldData.PRM || ''}` +
            ` ${this.heldData.RD || ''}` +
            ` ${this.$CAD.getAlias(4).getValue(this.heldData.STS) || this.heldData.STS || ''}` +
            ` ${this.$CAD.getAlias(4).getValue(this.heldData.POD) || this.heldData.POD || ''} ${this.heldData.POM || ''}`
    },
    getAddress () {
      return `${this.heldData.HNO || ''} ${this.heldData.RD || ''} ${this.heldData.STS || ''}`
    },
    getCityZip () {
      return `${this.heldData.A3 || ''} ${this.heldData.A1 || ''} ${this.heldData.PC || ''}`
    },
    async sendCADData (force) {
      if (!force) {
        if (!this.validateInput() || this.dataWasSent) return
      }

      if (!this.callGetter.isEmergency) return

      let url = this.getGlobalSetting('cad_spill_url')
      let port = this.getGlobalSetting('cad_spill_port_number')

      this.preparedCADData = this.prepareCADData()

      this.$socket.emit('create-call', {
        userId: this.authUserGetter.id,
        cad: this.preparedCADData,
        held: { ...this.heldData },
        adr: {
          address: this.getAddress(),
          city_zip: this.getCityZip()
        },
        call_type: this.preparedCADData.classOfService,
        position_number: this.position
      })

      if ((!port || !port.value?.current) && (!url || !url.value?.url)) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('no_cad_port_please_set')
        })

        this.clearOldData()

        return
      }

      url = url.value.url

      const realm = this.sipDefaultAddressGetter.realm

      port = parseInt(port.value?.current)

      if ((isNaN(port) || port <= 0) && !url) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('incorrect_cad_port')
        })

        this.clearOldData()

        return
      }

      if (!this.position) {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('no_cad_position_please_relogin')
        })

        this.clearOldData()

        return
      }

      const cad = this.$CAD
        .getFormat(4)
        .clearData()
        .setPosition(this.position)
        .setData(this.preparedCADData)

      const text = cad.formatData()

      if (port > 0) {
        this.$socket.emit('send-cad-text', {
          port,
          data: text.data,
          realm
        })

        this.$logger.debug('sendCADData sent by port.')
      }

      if (url) {
        const payload = {
          url,
          data: {
            held: {
              ...this.heldData,
              NAM: this.preparedCADData.phone,
              lat: this.preparedCADData.lat,
              lng: this.preparedCADData.lon,
              street_name: this.preparedCADData.street
            },
            adr: {
              comment: this.commentData,
              deviceInfo: this.deviceInfoData,
              providerInfo: this.serviceInfoData,
              serviceInfo: this.providerInfoData,
              subscriberInfo: this.subscriberInfoData
            },
            call_type: this.preparedCADData.classOfService,
            position_number: this.position
          }
        }

        const data = {
          type: 'json',
          method: 'CAD',
          call_id: this.$sip.answeredCallSession._request.call_id,
          position: this.position ?? null,
          direction: 'outgoing',
          write_time: Date.now(),
          sip_message: JSON.stringify(payload),
          sip_address: this.sipDefaultAddressGetter.sip_address
        }

        await apiService.callLogs.saveCallLogs(data)

        this.$socket.emit('send-cad-url', payload)
      }

      this.dataWasSent = true
      this.clearOldData()
    },
    clearOldData () {
      this.hasData.hasHeld = false
      this.hasData.hasComment = false
      this.hasData.hasDeviceInfo = false
      this.hasData.hasServiceInfo = false
      this.hasData.hasProviderInfo = false
      this.hasData.hasSubscriberInfo = false

      this.dataWasSent = true
    },
    validateInput () {
      return (
        this.hasData.hasHeld &&
        this.hasData.hasComment &&
        this.hasData.hasServiceInfo &&
        this.hasData.hasProviderInfo &&
        this.hasData.hasSubscriberInfo
      )
    }
  },
  mounted () {
    this.$event.listen('updated-held', this.updatedHELD)
    this.$event.listen('updated-adr-Comment', this.updatedADRComment)
    this.$event.listen('updated-adr-DeviceInfo', this.updatedADRDeviceInfo)
    this.$event.listen('updated-adr-ServiceInfo', this.updatedADRServiceInfo)
    this.$event.listen('updated-adr-ProviderInfo', this.updatedADRProviderInfo)
    this.$event.listen('updated-adr-SubscriberInfo', this.updatedADRSubscriberInfo)

    this.position = localStorageService.getPositionNumber()

    this.$event.listen('incoming-ended', e => {
      this.dataWasSent = false
    })

    this.$event.listen('outgoing-ended', e => {
      this.dataWasSent = false
    })

    this.$event.listen('incoming-failed', e => {
      this.dataWasSent = false
    })

    this.$event.listen('outgoing-failed', e => {
      this.dataWasSent = false
    })

    this.$event.listen('held_was_updated', e => {
      this.sendCADData(true)
    })
  }
}

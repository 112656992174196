export default {
  methods: {
    setLocations (Graphic, lat, lng) {
      if (!lat || !lng) return
      lng = parseFloat(lng)
      lat = parseFloat(lat)
      this.view.goTo({
        center: [lng, lat],
        zoom: 18
      })
      const pointGraphic = new Graphic({
        geometry: this.point(lng, lat),
        symbol: this.markerSymbol()
      })
      this.view.graphics.addMany([pointGraphic])
    },
    clearLocation () {
      this.view.goTo({
        center: [this.lng, this.lat],
        zoom: this.zoom
      })
      this.view.graphics.removeAll()
    },
    markerSymbol () {
      return {
        type: 'simple-marker',
        color: [226, 119, 40],
        outline: {
          color: [255, 255, 255],
          width: 2
        }
      }
    },
    point (lng, lat) {
      return {
        type: 'point',
        longitude: lng,
        latitude: lat
      }
    },
    addSearch (Search) {
      const search = new Search({
        view: this.view
      })
      this.view.ui.add(search, 'top-right')
    },
    fullScreen (Fullscreen) {
      const fullscreen = new Fullscreen({
        view: this.view
      })
      this.view.ui.add(fullscreen, 'top-left')
    },
    mapToggle (BasemapToggle) {
      const basemapToggle = new BasemapToggle({
        view: this.view,
        nextBasemap: 'satellite'
      })

      this.view.ui.add(basemapToggle, 'bottom-right')
    }
  }
}

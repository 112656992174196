<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="error"
        small
        min-width="0"
        @click="callMonitoring(`*69${dialNumber}`, ['Call-Monitoring: OBSERVE'])"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
        :disabled="callGetter.hasActiveCall || !sipGetter.registered"
      >
        <v-icon small color="white">
          mdi-binoculars
        </v-icon>
      </v-btn>
    </template>
    <span> {{ $t('observe')}}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import callMonitoringMixin from '@/mixins/sip/call-monitoring.mixin'
export default {
  name: 'ObserveButton',
  mixins: [callMonitoringMixin],
  props: ['dialNumber', 'agent'],
  computed: {
    ...mapGetters('global', [
      'sipGetter', 'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>

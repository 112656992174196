import store from '@/store'
import callMixin from '@/mixins/sip/call.mixin'
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'
import InfoReferMixin from '@/mixins/sip/info-refer-mixin'
export default {
  mixins: [callMixin, warmTransferMixin, InfoReferMixin],
  methods: {
    callOrTransfer (target) {
      if (store.getters['global/sipGetter'].registered) {
        if (!store.getters['global/callGetter'].hasActiveCall) {
          this.call(target.phone)
        } else if (target?.flash_hook) {
          this.infoRefer(target?.flash_hook)
        } else {
          this.warmTransfer(target.phone)
        }
      }
    }
  }
}

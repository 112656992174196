<template>
  <v-card flat class="fill-height pa-2">
    <v-row no-gutters class="fill-height">
      <template v-for="(item, index) in computedInfo">
        <v-col align-self="center" class="text-truncate" :key="index" cols="12">
          <span class="font-weight-bold headers">
            {{ item.title }}
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ item.value }}</span>
                  </template>
                  <span>{{ item.value }}</span>
          </v-tooltip>
        </v-col>
      </template>
      <v-col align-self="end" cols="12">
        <v-row dense>
          <v-col>
            <held-rebid-button />
          </v-col>
          <v-col>
            <clear-call-data-button/>
          </v-col>
          <v-col>
            <incorrect-location-button/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
/* Buttons */
import HeldRebidButton from '@/app/widgets/call-information-widget/components/buttons/HeldRebidButton'
import ClearCallDataButton from '@/app/widgets/call-information-widget/components/buttons/ClearCallDataButton'
import IncorrectLocationButton from '@/app/widgets/call-information-widget/components/buttons/IncorrectLocationButton'

export default {
  name: 'CallInfoComponent',
  components: {
    HeldRebidButton,
    ClearCallDataButton,
    IncorrectLocationButton
  },
  computed: {
    ...mapGetters('adr', [
      'commentGetter',
      'deviceInfoGetter',
      'providerInfoGetter',
      'serviceInfoGetter',
      'subscriberInfoGetter'
    ]),
    ...mapGetters('held', [
      'heldGetter'
    ]),
    ...mapGetters('callInformation', [
      'dateTimeGetter',
      'esrkNumberGetter'
    ]),
    ...mapGetters('settings', ['globalSettingsGetter', 'getGlobalSetting', 'sipDefaultAddressGetter']),
    computedInfo () {
      return [
        { title: this.$t('date_time') + ':', value: this.dateTimeGetter },
        { title: this.$t('provider') + ':', value: this.providerInfoGetter.DataProviderString },
        { title: this.$t('callback_number') + ':', value: this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', '') },
        { title: this.$t('esrk_number') + ':', value: this.esrkNumberGetter },
        { title: this.$t('class_of_service') + ':', value: this.serviceInfoGetter.ServiceType },
        { title: this.$t('name') + ':', value: this.subscriberInfoGetter?.SubscriberData?.vcard?.fn?.text },
        { title: this.$t('address') + ':', value: this.getAddress() },
        { title: this.$t('city_st_zip') + ':', value: this.getCityZip() },
        { title: this.$t('lat_lon') + ':', value: this.getLatLng() },
        { title: this.$t('altitude') + ':', value: this.heldGetter?.altitude },
        { title: this.$t('cell_sector_id') + ':', value: this.heldGetter.civicAddress.LOC },
        { title: this.$t('legacy_class_of_service') + ':', value: this.serviceInfoGetter?.LegacyClassOfService },
        { title: this.$t('confidence') + ':', value: this.heldGetter?.confidence },
        { title: this.$t('radius') + ':', value: this.heldGetter?.radius },
        { title: this.$t('comments') + ':', value: this.commentGetter.Comment }
      ]
    }
  },
  methods: {
    getAddress () {
      return `${this.heldGetter.civicAddress.HNO || ''} ${this.heldGetter.civicAddress.RD || ''} ${this.heldGetter.civicAddress.STS || ''}`
    },
    getCityZip () {
      return `${this.heldGetter.civicAddress.A3 || ''} ${this.heldGetter.civicAddress.A1 || ''} ${this.heldGetter.civicAddress.PC || ''}`
    },
    getLatLng () {
      return `${this.heldGetter.lat || ''} ${this.heldGetter.lng || ''}`
    }
  }
}
</script>

<style scoped>
  .headers{
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #4ea1e4;
  }
</style>

import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },

  methods: {
    data: () => ({
      actionsList: {}
    }),

    keyboardActions (action) {
      if (this.actionsList[action.code] && (!!action[this.actionsList[action.code].hotKey] || this.actionsList[action.code].hotKey === 'ignore')) {
        switch (this.actionsList[action.code].method) {
          case 'answerCall':
            this.answerCall()
            break
          case 'hangupCall':
            this.hangupCall()
            break
          case 'typePlus':
            this.longAction({ longClickValue: '+' })
            break
          case 'Delete':
            this.clearPhoneNumber()
            break
          default:
            this.clickToButton(this.actionsList[action.code].button)
            break
        }
      }
    },

    answerCall () {
      if (!this.callGetter.hasActiveCall && this.$sip.session.length > 0) {
        const elements = document.getElementsByClassName('call_list')
        const buttons = [...elements]
        const button = buttons.find(item => item._ripple.enabled)
        button.click()
      }
    },

    hangupCall () {
      if (this.callGetter.hasActiveCall) {
        const button = document.getElementById('hangupCall')
        button.click()
      }
    },

    clickToButton (element) {
      if (!this.$refs['number-type-area'].isFocused) {
        const button = document.getElementById(element)
        button.click()
      }
    },

    remoteActionsList () {
      return {
        KeyC: {
          name: 'Connection',
          hotKey: 'altKey',
          button: 'action_KeyC'
        },
        KeyR: {
          name: 'Registration',
          hotKey: 'altKey',
          button: 'action_KeyR'
        },
        KeyH: {
          name: 'Hold',
          hotKey: 'altKey',
          button: 'action_KeyH'
        },
        KeyM: {
          name: 'Mute',
          hotKey: 'altKey',
          button: 'action_KeyM'
        },
        KeyG: {
          name: 'Global Hold',
          hotKey: 'altKey',
          button: 'action_KeyG'
        },
        KeyI: {
          name: 'Hook Flash',
          hotKey: 'altKey',
          button: 'action_KeyI'
        },
        Enter: {
          name: 'Make a call',
          hotKey: 'ctrlKey',
          button: 'button_Enter'
        },
        Space: {
          name: 'Answer the call',
          hotKey: 'ctrlKey',
          method: 'answerCall'
        },
        AltLeft: {
          name: 'Hang up the call',
          hotKey: 'ctrlKey',
          method: 'hangupCall'
        },
        Numpad1: {
          name: 'Type 1',
          hotKey: 'ignore',
          button: 'button_Numpad1'
        },
        Numpad2: {
          name: 'Type 2',
          hotKey: 'ignore',
          button: 'button_Numpad2'
        },
        Numpad3: {
          name: 'Type 3',
          hotKey: 'ignore',
          button: 'button_Numpad3'
        },
        Numpad4: {
          name: 'Type 4',
          hotKey: 'ignore',
          button: 'button_Numpad4'
        },
        Numpad5: {
          name: 'Type 5',
          hotKey: 'ignore',
          button: 'button_Numpad5'
        },
        Numpad6: {
          name: 'Type 6',
          hotKey: 'ignore',
          button: 'button_Numpad6'
        },
        Numpad7: {
          name: 'Type 7',
          hotKey: 'ignore',
          button: 'button_Numpad7'
        },
        Numpad8: {
          name: 'Type 8',
          hotKey: 'ignore',
          button: 'button_Numpad8'
        },
        Numpad9: {
          name: 'Type 9',
          hotKey: 'ignore',
          button: 'button_Numpad9'
        },
        Numpad0: {
          name: 'Type 0',
          hotKey: 'ignore',
          button: 'button_Numpad0'
        },
        NumpadAdd: {
          name: 'Keypad +',
          hotKey: 'ignore',
          method: 'typePlus'
        },
        Delete: {
          name: 'Clear Dial Pad',
          hotKey: 'ignore',
          method: 'Delete'
        },
        NumpadSubtract: {
          name: 'Type -',
          hotKey: 'ignore',
          button: 'button_NumpadSubtract'
        },
        NumpadMultiply: {
          name: 'Type *',
          hotKey: 'ignore',
          button: 'button_NumpadMultiply'
        },
        NumpadDivide: {
          name: 'Type #',
          hotKey: 'ignore',
          button: 'button_NumpadDivide'
        },
        Backspace: {
          name: 'Backspace',
          hotKey: 'ignore',
          button: 'button_Backspace'
        }
      }
    }
  },
  async mounted () {
    this.actionsList = this.remoteActionsList()
    this.$event.listen('keydown', e => {
      this.keyboardActions(e)
    })
  }
}

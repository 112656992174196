var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"parent-height",staticClass:"px-4 py-2",attrs:{"dense":"","cypress":"agents_table","dark":_vm.$vuetify.theme.dark,"items":_vm.sortedItems,"height":_vm.fixedHeight - 80,"item-key":"id","loading":_vm.loading,"headers":_vm.headers,"loading-text":_vm.$t('loading'),"selectable-key":"id","items-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"title":item.name}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.first_name + ' ' + item.last_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.first_name + ' ' + item.last_name))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.state == 'READY')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]):_vm._e(),(item.state == 'ON_CALL')?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-phone")]):_vm._e(),(item.state == 'ON_EMERGENCY_CALL')?_c('v-badge',{attrs:{"color":"error","overlap":"","top":"","right":"","icon":"mdi-heart"}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-phone")])],1):_vm._e(),(!item.state || item.state == 'NOT_READY')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-cancel")]):_vm._e()]}},{key:"item.dial",fn:function(ref){
var item = ref.item;
return [_c('call-back-button',{attrs:{"dialNumber":item.presence_id}}),_c('call-transfer-button',{attrs:{"dialNumber":item.presence_id}}),_c('observe-button',{attrs:{"agent":Object.assign({}, item),"dialNumber":item.presence_id}}),_c('whisper-button',{attrs:{"agent":Object.assign({}, item),"dialNumber":item.presence_id}}),_c('barge-button',{attrs:{"agent":Object.assign({}, item),"dialNumber":item.presence_id}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
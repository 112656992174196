export default {
  methods: {
    sendDTMF (session, dtmfCode) {
      session.sendDTMF(dtmfCode, {
        transportType: 'RFC2833',
        interToneGap: 100
      })
    }
  }
}

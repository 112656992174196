<template>
  <v-btn
      class="text-icon-button"
      :color="irrVoiceState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.hasActiveCall"
      height="100%"
  >
    <v-icon>{{ irrVoiceIcon }}</v-icon>
    <div class="font-weight-bold pt-2"> {{ $t('irr_voice')}} <br> {{ $t('monitor')}}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IrrVoiceButton',
  data: () => ({
    disableIrrVoice: false,
    irrVoiceState: false,
    irrVoiceIcon: 'mdi-microphone-off'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>

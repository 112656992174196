import store from '@/store'
import sendDtmfCodeMixin from '@/mixins/sip/send-dtmf-code.mixin'
import sipHelper from '@/helpers/sip.helper'
export default {
  mixins: [sendDtmfCodeMixin],
  methods: {
    infoRefer (target) {
      target = sipHelper.cleanTarget(target)
      const event = 'event flashhook\r\n'
      this.$sip.answeredCallSession.sendInfo('application/broadsoft; version=1.0', event)
      // const match = !!(e.session._request?.headers?.['X-Trigger']?.[0].raw?.match(/Audiocodes/))
      setTimeout(async () => {
        this.sendDTMF(this.$sip.answeredCallSession, target)
        await store.dispatch('notifications/notificationSnackBarAction', {
          color: 'success',
          icon: 'mdi-webhook',
          text: this.$t('call_flash_hook_transfer'),
          show: true,
          timeout: 5000
        })
      }, 500)
    },
    sendInfo () {
      const event = 'event flashhook\r\n'
      this.$sip.answeredCallSession.sendInfo('application/broadsoft; version=1.0', event)
    }
  }
}

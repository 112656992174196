<template>
    <v-row dense class="fill-height full-width" v-if="$can('can_see_map')">
      <v-col cols="12 py-0 d-flex" id="map-container"></v-col>
    </v-row>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { loadModules } from 'esri-loader'
/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'
export default {
  name: 'MapsTab',
  data: () => ({
    graphic: null,
    lng: -105,
    lat: 43.95,
    zoom: 2.9,
    themeMode: 'dark-gray-vector'
  }),
  mixins: [mapLocationMixin],
  computed: {
    ...mapGetters('held', [
      'heldGetter'
    ]),
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  watch: {
    'heldGetter.lat' () {
      this.setLocations(this.graphic, this.heldGetter.lat, this.heldGetter.lng)
    },
    async 'callGetter.clearingMap' (val) {
      if (val) {
        this.clearLocation()
        await this.clearingMapAction(false)
      }
    }
  },
  methods: {
    ...mapActions('global', [
      'clearingMapAction'
    ]),
    loadMap () {
      loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/config',
        'esri/widgets/Search',
        'esri/widgets/Fullscreen',
        'esri/widgets/BasemapToggle'
      ], {
        css: true
      })
        .then(([ArcGISMap, MapView, Graphic, esriConfig, Search, Fullscreen, BasemapToggle]) => {
          this.graphic = Graphic
          esriConfig.apiKey = process.env.ESRI_MAP_API_KEY
          const map = new ArcGISMap({
            basemap: this.themeMode
          })
          this.view = new MapView({
            map: map,
            center: [this.lng, this.lat],
            zoom: this.zoom,
            container: document.getElementById('map-container')
          })

          this.mapToggle(BasemapToggle)
          this.addSearch(Search)
          this.fullScreen(Fullscreen)
        })
    }
  },
  mounted () {
    this.loadMap()
    this.$event.listen('clear-map-locations', () => {
      this.clearLocation()
    })
  }
}
</script>
<style lang="scss">
</style>

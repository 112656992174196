<template>
  <v-data-table
    class="px-4 py-2"
    :headers="headers"
    :items="items || []"
    item-key="id"
    selectable-key="id"
    single-select
    dense
    :dark="$vuetify.theme.dark"
    :height="fixedHeight - 150"
    ref="parent-height"
    :loading="loading"
    :loading-text="$t('loading')"
    :footer-props="{itemsPerPageOptions : [5,10,15]}"
  >

    <template v-slot:[`top`]>

      <v-text-field :disabled="loading"
                    v-model="search" clearable @click:clear="clearSearch"
                    :label="$t('search')"
      >
        <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

      </v-text-field>
    </template>

    <template v-slot:[`item.from`]="{ item }">
      {{ item.call_from }}
    </template>

    <template v-slot:[`item.datetime`]="{ item }">
      {{ (new Date(item.created_at)) | dateFormatter }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <call-back-button :dialNumber="item.call_from"/>
      <call-resolve-button :data="item" />
      <call-detail-button :data="item" reason="abandoned"/>
    </template>

  </v-data-table>
</template>

<script>
// import mediaHelper from '@/helpers/media.helper'
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
/* Buttons */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallDetailButton from '@/app/shared/buttons/CallDetailButton'
import CallResolveButton from '@/app/shared/buttons/CallResolveButton'

/* Vuex */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AbandonedCallsComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {
    CallBackButton,
    CallResolveButton,
    CallDetailButton
  },
  data: (vm) => ({
    search: '',
    loading: false,
    headers: [
      { text: vm.$t('from'), value: 'from', sortable: false },
      { text: vm.$t('date'), value: 'datetime', sortable: false },
      { text: vm.$t('action'), value: 'action', sortable: false }
    ],
    totalItems: 0,
    items: [],
    options: {
      itemsPerPage: 15
    }
  }),
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('abandonedCalls', ['getAbandonedGetter'])
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),
    ...mapActions('abandonedCalls', ['setUnresolvedAbandonedCallsCountAction']),
    clearSearch () {
      this.search = ''
    },
    notify () {
      // mediaHelper.setNewAbandonCallAudio()

      // this.notificationSnackBarAction({
      //   text: this.$t('new_abandon_call'),
      //   color: 'info',
      //   show: true
      // })

      // const audio = document.getElementById('new-abandon-call')

      // if (!audio) return

      // audio.play()
    },
    readAbandonCalls (search = {}) {
      if (!this.sipDefaultAddressGetter) return

      let agents = []

      let query = this.$firestore.collection('abandoned_calls')
        .where('realm', '==', this.sipDefaultAddressGetter.realm)

      if (search.call_from) {
        query = query.where('call_from', '==', search.call_from)
      }

      query = query.orderBy('created_at', 'desc')

      query.onSnapshot(querySnapshot => {
        this.totalItems = querySnapshot.size

        querySnapshot.forEach((doc) => {
          const agent = { ...doc.data() }

          agent.id = doc.id

          agents.push(agent)
        })

        this.items = [...agents]

        agents = []

        this.loading = false
      })
    }
  },
  watch: {
    async search () {
      this.selected = []

      this.readAbandonCalls({
        call_from: this.search
      })
    },
    'totalItems' () {
      this.setUnresolvedAbandonedCallsCountAction(this.totalItems || 0)
    },
    'sipDefaultAddressGetter.realm' () {
      this.readAbandonCalls()
    }
  },
  async mounted () {
    this.loading = true

    this.readAbandonCalls()

    this.setUnresolvedAbandonedCallsCountAction(this.totalItems || 0)

    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })

    setInterval(() => {
      if (this.totalItems && this.totalItems.value) this.notify()
    }, 20 * 1000)
  }
}
</script>

<style scoped>

</style>
